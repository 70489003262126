module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-169357592-1"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jeevz","short_name":"Jeevz","start_url":"/","background_color":"#222761","theme_color":"#FFFFFF","display":"browser","icon":"src/img/jeevz-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"882b4a20d9f62a2aabffb6e13a8aeb24"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"yEELHIhoWgnvm62qS8dt4f3MZtUWSuCF","trackPage":false},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"jeevz","path":"/preview","previews":true,"pages":[{"type":"Use Case","match":"/for/:uid","path":"/use-cases","component":"/home/runner/work/jeevz-marketing-site/jeevz-marketing-site/src/templates/useCasePage.js"},{"type":"Landing Page","match":"/for/:uid","path":"/lp","component":"/home/runner/work/jeevz-marketing-site/jeevz-marketing-site/src/templates/landingPage.js"},{"type":"City","match":"/cities/:uid","path":"/city","component":"/home/runner/work/jeevz-marketing-site/jeevz-marketing-site/src/templates/cityPage.js"},{"type":"Static Page","match":"/:uid","path":"/preview","component":"/home/runner/work/jeevz-marketing-site/jeevz-marketing-site/src/templates/staticPage.js"},{"type":"Offer Page","match":"/offers/:uid","path":"/offer","component":"/home/runner/work/jeevz-marketing-site/jeevz-marketing-site/src/templates/offerPage.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
