// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/@prismicio/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-21-aug-test-laborday-1-js": () => import("./../../../src/pages/21_Aug_Test_Laborday_1.js" /* webpackChunkName: "component---src-pages-21-aug-test-laborday-1-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-drivers-js": () => import("./../../../src/pages/drivers.js" /* webpackChunkName: "component---src-pages-drivers-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-city-page-js": () => import("./../../../src/templates/cityPage.js" /* webpackChunkName: "component---src-templates-city-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-offer-page-js": () => import("./../../../src/templates/offerPage.js" /* webpackChunkName: "component---src-templates-offer-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/staticPage.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-use-case-page-js": () => import("./../../../src/templates/useCasePage.js" /* webpackChunkName: "component---src-templates-use-case-page-js" */)
}

