export const linkResolver = (doc) => {
  if (doc._meta) {
    // TODO: this feels like a hack but the link resolver
    // is always called with the entire document passed in
    // but it seems like it's only ever called with the `_meta`
    return linkResolver(doc._meta);
  }

  if (!doc || !doc.type) {
    throw new Error(
      ```
      linkResolver called without having required _meta properties. 
      Check the passed in query contains the relevant fields for querying 
      the document and the _meta fields.
      ```
    );
  }

  // URL for a category type
  if (doc.type === "landing_page") {
    return `/for/${doc.uid}`;
  }

  // URL for a category type
  if (doc.type === "offer_page") {
    return `/offers/${doc.uid}`;
  }

  // URL for a category type
  if (doc.type === "city") {
    return `/cities/${doc.uid}`;
  }

  // URL for a category type
  if (doc.type === "use_case") {
    return `/for/${doc.uid}`;
  }

  // URL for a page type
  if (doc.type === "page") {
    return `/${doc.uid}`;
  }

  // Backup for all other types
  return "/";
};
