const {
  registerLinkResolver,
} = require("@prismicio/gatsby-source-prismic-graphql");
const { linkResolver } = require("./src/utils/linkResolver");

registerLinkResolver(linkResolver);

exports.onRouteUpdate = ({ location }) => {
  if (!window.analytics || typeof window.analytics.page !== "function") {
    return;
  }

  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;

    window.analytics.page({
      path: pagePath,
      url: location.href,
    });
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }
};
